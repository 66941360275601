import {
  CurrencyAmount,
  DateRange,
  SearchSessionV2,
  TransferPartner,
} from "@/generated/search.openapi";
import {
  LoyaltyAccount,
  LoyaltyPerks,
  LoyaltyProgramEnum,
} from "@/generated/email_parser.openapi";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import {
  addDays,
  eachDayOfInterval,
  endOfMonth,
  format,
  isMonday,
  isSaturday,
  // isSunday,
  startOfMonth,
} from "date-fns";
import {
  HotelData,
  TransferPartnersMapping,
} from "@/app/[bookingType]/search/context/searchProvider";
import { Calendar, Search } from "lucide-react";
import MapUnfold from "@/components/ui/svgComponents/MapUnfold";
import PointsIcon from "@/components/ui/svgComponents/PointsIcon";
import { DateRangeWithFlexibleData } from "@/app/[bookingType]/search/dateRangeWithFlexible/DateRangeWithFlexible";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getSelectedLoyaltyAccount(
  loyaltyAccounts: LoyaltyAccount[],
  loyaltyProgram: string
) {
  return loyaltyAccounts
    .filter((account) => account.loyaltyProgram === loyaltyProgram)
    .sort((a, b) => {
      const pointsA = a.loyaltyPoints ?? -1; // Use nullish coalescing operator
      const pointsB = b.loyaltyPoints ?? -1; // Use nullish coalescing operator
      return pointsB - pointsA;
    })[0];
}

export function objectToUrlParams(obj: Record<string, string>) {
  return Object.entries(obj)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
}

export function diffTimeInHourAndMin(date1: Date, date2: Date) {
  const diffInMilliSeconds = Math.abs(date1.getTime() - date2.getTime());
  return `${Math.floor(diffInMilliSeconds / 3600000)} hr ${
    Math.floor(diffInMilliSeconds / 60000) % 60
  } min`;
}

export function calculateNumberOfNights(date1: Date, date2: Date) {
  // Create new dates without time information
  const startDate = new Date(
    Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())
  );
  const endDate = new Date(
    Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate())
  );

  const diffInMilliSeconds = Math.abs(startDate.getTime() - endDate.getTime());
  return Math.ceil(diffInMilliSeconds / 86400000);
}

export function camelToSnakeCase(camelStr: string) {
  return camelStr.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export function snakeToCamelCase(snakeStr: string) {
  return snakeStr.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
}

export const convertLocalDateToUTC = (localDate: Date) => {
  const dateInUTC = new Date(`${format(localDate, "yyyy-MM-dd")}T00:00:00Z`);
  return dateInUTC;
};

export const convertUTCToLocalDateIgnoringTimezone = (
  _utcDate: Date | string
) => {
  const utcDate = getDateFormat(_utcDate);
  return new Date(
    utcDate.getUTCFullYear(),
    utcDate.getUTCMonth(),
    utcDate.getUTCDate(),
    utcDate.getUTCHours(),
    utcDate.getUTCMinutes(),
    utcDate.getUTCSeconds(),
    utcDate.getUTCMilliseconds()
  );
};

export const getDateFormat = (date?: string | Date) => {
  return typeof date === "string" ? new Date(date) : (date as Date);
};

export const updateHtmlListStyles = (
  instructions: string | string[]
): string | string[] => {
  if (typeof instructions === "string") {
    return [
      instructions
        .replace(/<ul>/g, '<ul class="list-disc list-outside pl-4">')
        .replace(/<ol>/g, '<ol class="list-decimal list-outside pl-4">'),
    ];
  }
  return instructions?.map((instruction) => {
    return instruction
      .replace(/<ul>/g, '<ul class="list-disc list-outside pl-4">')
      .replace(/<ol>/g, '<ol class="list-decimal list-outside pl-4">');
  });
};

export const camelCaseToHumanReadable = (camelCaseString: string) => {
  return camelCaseString
    .replace(/([A-Z])/g, " $1")
    .trim()
    .replace(/^./, (str) => str.toUpperCase());
};

export const snakeCaseToHumanReadable = (snakeCaseString: string) => {
  return snakeCaseString
    .replace(/_/g, " ")
    .replace(/^./, (str) => str.toUpperCase());
};

export const convertJsonToUrlParams = (
  queryParams: Record<string, string | number | boolean>
) => {
  return (
    "?" +
    encodeURI(
      Object.entries(queryParams)
        .map(([key, value]) => `${key}=${value}`)
        .join("&")
    )
  );
};

export const formatNumber = (
  number: number,
  maximumFractionDigits: number = 2
) => {
  return new Intl.NumberFormat("en-US", { maximumFractionDigits }).format(
    number
  );
};

export const formatCurrency = (
  amount: string | number,
  currency: string = "USD",
  maximumFractionDigits: number = 2
) => {
  let _amount;
  if (typeof amount === "string") {
    _amount = Number(amount);
  } else {
    _amount = amount;
  }

  // Validate the currency code
  const validCurrency = isValidCurrency(currency) ? currency : "USD";

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: validCurrency,
    maximumFractionDigits: maximumFractionDigits,
  }).format(_amount);
};

export const convertRawCurrencyToString = (
  currenctAmt: CurrencyAmount,
  isLocalized: boolean = false,
  maximumFractionDigits: number = 2
) => {
  if (currenctAmt?.currency === undefined || currenctAmt?.currency === null) {
    return currenctAmt?.value ? `${currenctAmt?.value}` : "";
  }
  if (currenctAmt?.currency === "points") {
    return `${Intl.NumberFormat("en-US").format(currenctAmt.value)}`;
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: isLocalized
      ? currenctAmt.localizedAmount?.currency
      : currenctAmt.currency,
    maximumFractionDigits: maximumFractionDigits,
  }).format(
    isLocalized
      ? (currenctAmt.localizedAmount?.value as number)
      : currenctAmt.value
  );
};

// Helper function to check if the currency code is valid
const isValidCurrency = (currency: string) => {
  try {
    // Attempt to format a dummy number to check if the currency is recognized
    new Intl.NumberFormat("en-US", { style: "currency", currency }).format(0);
    return true;
  } catch {
    return false;
  }
};

export const imgCategoriesMapping: {
  [key: string]: string;
} = {
  pool: "/assets/image-category/pool.svg",
  lobby: "/assets/image-category/lobby.svg",
  spa: "/assets/image-category/spa.svg",
  exterior: "/assets/image-category/exterior.svg",
  room: "/assets/image-category/room.svg",
  "fitness center": "/assets/image-category/fitness-center.svg",
  views: "/assets/image-category/views.svg",
  "food & drink": "/assets/image-category/food-drink.svg",
  casino: "/assets/image-category/casino.svg",
};

export const replaceUrl = (newUrl: string) => {
  window.history.replaceState(
    { ...window.history.state, as: newUrl, url: newUrl },
    "",
    newUrl
  );
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeEachWord = (str: string) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const getTimeDataFromSearchResponse = (
  searchResponse: SearchSessionV2 | undefined
) => {
  const checkin =
    searchResponse?.request?.hotels?.searchDates?.checkin &&
    convertUTCToLocalDateIgnoringTimezone(
      searchResponse?.request?.hotels?.searchDates?.checkin
    );
  const checkout =
    searchResponse?.request?.hotels?.searchDates?.checkout &&
    convertUTCToLocalDateIgnoringTimezone(
      searchResponse?.request?.hotels?.searchDates?.checkout
    );

  const nightCount =
    checkout && checkin ? calculateNumberOfNights(checkin, checkout) : 0;

  return {
    checkin,
    checkout,
    nightCount,
  };
};

export const screenInnerWidth =
  typeof window !== "undefined" ? window.innerWidth : 0;

export const screenInnerHeight =
  typeof window !== "undefined" ? window.innerHeight : 0;

export const loyaltyAccountsColorMapping: {
  [key in LoyaltyProgramEnum]: string;
} = {
  [LoyaltyProgramEnum.AAdvantage]: "",
  [LoyaltyProgramEnum.HiltonHonors]: "#256885",
  [LoyaltyProgramEnum.IhgOneRewards]: "#964721",
  [LoyaltyProgramEnum.MarriottBonvoy]: "#871035",
  [LoyaltyProgramEnum.MileagePlan]: "",
  [LoyaltyProgramEnum.MileagePlus]: "",
  [LoyaltyProgramEnum.SkyMiles]: "",
  [LoyaltyProgramEnum.WorldOfHyatt]: "#A16F01",
  [LoyaltyProgramEnum.TrueBlue]: "",
  [LoyaltyProgramEnum.RapidRewards]: "",
  [LoyaltyProgramEnum.Gold]: "",
  [LoyaltyProgramEnum.AmexEveryDay]: "",
  [LoyaltyProgramEnum.ThePlatinumCard]: "",
  [LoyaltyProgramEnum.BlueBusinessPlus]: "",
  [LoyaltyProgramEnum.BusinessPlatinumCard]: "",
  [LoyaltyProgramEnum.ChaseFreedom]: "",
  [LoyaltyProgramEnum.ChaseFreedomFlex]: "",
  [LoyaltyProgramEnum.ChaseFreedomRise]: "",
  [LoyaltyProgramEnum.ChaseFreedomUnlimited]: "",
  [LoyaltyProgramEnum.ChaseSapphire]: "",
  [LoyaltyProgramEnum.ChaseSapphirePreferred]: "",
  [LoyaltyProgramEnum.ChaseSapphireReserve]: "",
  [LoyaltyProgramEnum.Bilt]: "",
  [LoyaltyProgramEnum.WyndhamRewards]: "#0070B8",
  [LoyaltyProgramEnum.AccorLiveLimitless]: "#E29C16",
  [LoyaltyProgramEnum.InkBusinessUnlimited]: "",
  [LoyaltyProgramEnum.InkBusinessPlus]: "",
  [LoyaltyProgramEnum.InkBusinessPreferred]: "",
  [LoyaltyProgramEnum.ChoicePrivilegesRewards]: "#E26812",
  [LoyaltyProgramEnum.BestWesternRewards]: "",
  [LoyaltyProgramEnum.BiltRewards]: "",
  [LoyaltyProgramEnum.ChaseUltimateRewards]: "",
  [LoyaltyProgramEnum.MembershipRewards]: "",
  [LoyaltyProgramEnum.BarclaycardRewardsProgram]: "",
  [LoyaltyProgramEnum.BankOfAmericaPreferredRewards]: "",
  [LoyaltyProgramEnum.KrisFlyerPartner]: "",
  [LoyaltyProgramEnum.AsiaMiles]: "",
  [LoyaltyProgramEnum.LeadersClub]: "",
  [LoyaltyProgramEnum.FlyingBlue]: "",
  [LoyaltyProgramEnum.CityThankYouRewards]: "",
  [LoyaltyProgramEnum.BankOfAmericaTravelRewards]: "",
  [LoyaltyProgramEnum.Aeroplan]: "",
  [LoyaltyProgramEnum.TheExecutiveClub]: "",
  [LoyaltyProgramEnum.FlyingClub]: "",
  [LoyaltyProgramEnum.AerClub]: "",
  [LoyaltyProgramEnum.EmiratesSkywards]: "",
  [LoyaltyProgramEnum.PrivilegeClub]: "",
  [LoyaltyProgramEnum.AviancaLifeMiles]: "",
  [LoyaltyProgramEnum.Skypass]: "",
  [LoyaltyProgramEnum.AeromexicoRewards]: "",
  [LoyaltyProgramEnum.AnaMileageClub]: "",
  [LoyaltyProgramEnum.MilesMore]: "",
  [LoyaltyProgramEnum.IberiaPlus]: "",
  [LoyaltyProgramEnum.QantasFrequentFlyer]: "",
};

export const sortHotelByPointPercentile = (a: HotelData, b: HotelData) => {
  const aPercentile = a.pointsPercentile;
  const bPercentile = b.pointsPercentile;
  if (aPercentile === undefined || aPercentile === 0) {
    return bPercentile === undefined || bPercentile === 0 ? 0 : 1;
  }
  return aPercentile && bPercentile ? bPercentile - aPercentile : -1;
};

export const sortHotelByCashPercentile = (a: HotelData, b: HotelData) => {
  const aPercentile = a.cashPercentile;
  const bPercentile = b.cashPercentile;
  if (aPercentile === undefined || aPercentile === 0) {
    return bPercentile === undefined || bPercentile === 0 ? 0 : 1;
  }
  return bPercentile && aPercentile ? aPercentile - bPercentile : -1;
};

export const xlBreakPoint = 1350;
export const lgBreakPoint = 1100;
export const mdBreakPoint = 768;
export const smallHeightBreakPoint = 750;

export const checkWebview = (): boolean => {
  const userAgent = navigator.userAgent.toLowerCase();

  // Define patterns for webview detection
  const webviewPatterns = [
    /wv/,
    /webview/,
    /ip(hone|od|ad).*applewebkit(?!.*safari)/,
    /android.*(version\/[0-9]\.[0-9]).*chrome\/[0-9]{2}\.[0-9]{3}\.[0-9]{2}/,
    /; wv\)/,
  ];

  // Check userAgent against each pattern
  const isWebview = webviewPatterns.some((pattern) => pattern.test(userAgent));

  return isWebview;
};

export const getRating = (rating: number) => {
  return (rating * 2).toFixed(1);
};

const getAllPossibleNightsForAMonth = (
  selectedMonth: Date,
  nights: number
): DateRange[] => {
  const monthStart = startOfMonth(selectedMonth);
  const monthEnd = endOfMonth(monthStart);

  const allDates = eachDayOfInterval({
    start: monthStart,
    end: monthEnd,
  });

  const nightsRanges: DateRange[] = [];

  for (let i = 0; i < allDates.length; i++) {
    const date = allDates[i];
    const endDate = addDays(date, nights);
    if (endDate <= monthEnd) {
      nightsRanges.push({ startDate: date, endDate });
    }
  }

  return nightsRanges;
};

const getWeekDayDatesForAMonth = (selectedMonth: Date): DateRange[] => {
  const monthStart = startOfMonth(selectedMonth);
  const monthEnd = endOfMonth(monthStart);

  const allDates = eachDayOfInterval({
    start: monthStart,
    end: monthEnd,
  });

  const weekdays: DateRange[] = [];

  for (let i = 0; i < allDates.length; i++) {
    const date = allDates[i];
    if (isMonday(date)) {
      weekdays.push({ startDate: date, endDate: addDays(date, 4) });
    }
  }

  return weekdays;
};

// const getWeekendDatesForAMonth = (selectedMonth: Date): DateRange[] => {
//   const monthStart = startOfMonth(selectedMonth);
//   const monthEnd = endOfMonth(monthStart);

//   const allDates = eachDayOfInterval({
//     start: monthStart,
//     end: monthEnd,
//   });

//   const weekends: DateRange[] = [];

//   for (let i = 0; i < allDates.length; i++) {
//     const date = allDates[i];
//     if (isSaturday(date)) {
//       const sunday = allDates[i + 1];
//       if (sunday && isSunday(sunday)) {
//         weekends.push({ startDate: date, endDate: sunday });
//         i++;
//       }
//     }
//   }

//   return weekends;
// };

const getWeekendDatesForAMonthFriAndSat = (
  selectedMonth: Date
): DateRange[] => {
  const monthStart = startOfMonth(selectedMonth);
  const monthEnd = endOfMonth(monthStart);

  const allDates = eachDayOfInterval({
    start: monthStart,
    end: monthEnd,
  });

  const weekends: DateRange[] = [];

  for (let i = 0; i < allDates.length; i++) {
    const date = allDates[i];
    if (isSaturday(date)) {
      const friday = allDates[i - 1];
      const sunday = allDates[i + 1];
      if (friday && sunday) {
        weekends.push({ startDate: friday, endDate: sunday });
      }
    }
  }

  return weekends;
};

export const defaultInitialPageRoute = "/hotel/search";

export const navItems = [
  {
    key: "hotel-search",
    title: "Search",
    href: "/hotel/search",
    icon: <Search className="mr-2 h-4 w-4" />,
  },
  {
    key: "points",
    title: "Points",
    href: "/loyalty-dashboard",
    icon: <PointsIcon className="mr-2 h-4 w-4" />,
  },
  {
    key: "trips",
    title: "Trips",
    href: "/my-trips",
    icon: <Calendar className="mr-2 h-4 w-4" />,
  },
  {
    key: "map",
    title: "Map",
    href: "/travel-history",
    icon: <MapUnfold className="mr-2 h-4 w-4" />,
  },
];

type SocialMediaIcon = {
  link: string;
  image: string;
  imageTransparent: string;
  alt: string;
  name: string;
};

export const isValidEmail = (email: string) => {
  return new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$").test(
    email
  );
};
export const isNumber = (value: string | number) => {
  const num = typeof value === "number" ? `${value}` : value;
  return new RegExp("^[0-9]+$").test(num);
};
export const socialMediaIcons: SocialMediaIcon[] = [
  {
    name: "instagram",
    link: "https://www.instagram.com/gondolaai",
    image: "/assets/icons/instagram.svg",
    imageTransparent: "/assets/icons/instagram-transparent.svg",
    alt: "instagram",
  },
  {
    name: "linkedin",
    link: "https://www.linkedin.com/company/gondolaai",
    image: "/assets/icons/linkedin.svg",
    imageTransparent: "/assets/icons/linkedin-transparent.svg",
    alt: "linkedin",
  },
  {
    name: "twitter",
    link: "https://twitter.com/gondola_ai",
    image: "/assets/icons/twitter.svg",
    imageTransparent: "/assets/icons/twitter-transparent.svg",
    alt: "twitter",
  },
];

export const getFlexiableDates = (
  data: DateRangeWithFlexibleData
): DateRange[] => {
  if (!data.flexible) return [];
  const {
    flexible: { selectedMonth, nights, stayLength },
  } = data;
  if (stayLength === "Nights") {
    return getAllPossibleNightsForAMonth(selectedMonth, nights);
  }
  if (stayLength === "Weekend") {
    return getWeekendDatesForAMonthFriAndSat(selectedMonth);
  }
  return getWeekDayDatesForAMonth(selectedMonth);
};

export const getTransferPartnersMapping = (
  availableTransferPartners: TransferPartner[]
): TransferPartnersMapping => {
  const mapping = {} as { [key: string]: LoyaltyProgramEnum[] };
  availableTransferPartners.forEach((partner) => {
    mapping[partner.fromLoyaltyProgram]
      ? mapping[partner.fromLoyaltyProgram].push(
          partner.toLoyaltyProgram as LoyaltyProgramEnum
        )
      : (mapping[partner.fromLoyaltyProgram] = [
          partner.toLoyaltyProgram as LoyaltyProgramEnum,
        ]);
  });
  return mapping;
};

export const getTransferPartnersFromLoyalty = (
  loyaltyProgramName: string,
  availableTransferPartners: TransferPartner[]
) => {
  return availableTransferPartners.filter(
    (transferPartners) =>
      transferPartners.toLoyaltyProgram === loyaltyProgramName
  );
};

export const getLoyaltyCardBGFromLoyaltyIndex = (index?: number) => {
  switch (true) {
    case index === 0:
      return "bg-card-background-light";
    case index === 1:
      return "card-linear-gradient-bronze";
    case index === 2:
      return "card-linear-gradient-silver";
    case index === 3:
      return "card-linear-gradient-gold";
    case index === 4:
      return "card-linear-gradient-titanium";
    case index === 5:
      return "card-linear-gradient-platinum";
    default:
      return "bg-card-background-light";
  }
};

export const getLoyaltyTagFromLoyaltyIndex = (index?: number) => {
  switch (true) {
    case index === 1:
      return "loyalty-tag-bronze";
    case index === 2:
      return "loyalty-tag-silver";
    case index === 3:
      return "loyalty-tag-gold";
    case index === 4:
      return "loyalty-tag-titanium";
    case index === 5:
      return "loyalty-tag-platinum";
    default:
      return "loyalty-tag-default";
  }
};

export const getPerksFromLoyalty = (loyalty: LoyaltyAccount) => {
  if (!loyalty.loyaltyPerks) return null;
  return Object.entries(loyalty.loyaltyPerks)
    .filter(
      ([key, value]) => key.toLowerCase().includes("perks") && Boolean(value)
    )
    .map(([key, value]) => ({
      icon: `/assets/loyalty-perks/${camelToSnakeCase(key)}.svg`,
      text: value,
    }));
};

export const getPerksFromLoyaltyPerks = (loyaltyPerks: LoyaltyPerks) => {
  return Object.entries(loyaltyPerks)
    .filter(
      ([key, value]) => key.toLowerCase().includes("perks") && Boolean(value)
    )
    .map(([key, value]) => ({
      icon: `/assets/loyalty-perks/${camelToSnakeCase(key)}.svg`,
      text: value,
    }));
};

export const scrollSmoothToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const institutionCodes = [
  "amex",
  "bank_of_america",
  "barclaycardus",
  "chase",
  "citibank",
  "us_bank",
];

// TODO: This way of getting institution code is not reliable, need to find a better way OR add a field in the loyalty account object
export const getInstitutionCodeFromProgramName = (programName: string) => {
  const arr = programName.split(" ").map((word) => word.toLowerCase());
  const code = institutionCodes.find((code) => arr.includes(code));
  return code || null;
};

export const formatCapsText = (text: string) => {
  return text
    .toLowerCase()
    .split(/[_ ]+/)
    .map((word) => capitalizeFirstLetter(word))
    .join(" ");
};

export const getTransferRatioValue = (transferRatio: number) => {
  const ratio = (1 / transferRatio).toFixed(1);
  return ratio.split(".")[1] === "0" ? ratio.split(".")[0] : ratio;
};

export const getCountryName = (countryCode: string) => {
  const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
  return regionNames.of(countryCode);
};
export const getNumberPostfix = (number: number): string => {
  const lastDigit = number % 10;
  const lastTwoDigits = number % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return "th";
  }

  switch (lastDigit) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
