/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GondolaMappedHotelStay } from './GondolaMappedHotelStay';
import {
    GondolaMappedHotelStayFromJSON,
    GondolaMappedHotelStayFromJSONTyped,
    GondolaMappedHotelStayToJSON,
} from './GondolaMappedHotelStay';

/**
 * 
 * @export
 * @interface GondolaMappedBiggestHotelPointsRedeemed
 */
export interface GondolaMappedBiggestHotelPointsRedeemed {
    /**
     * 
     * @type {GondolaMappedHotelStay}
     * @memberof GondolaMappedBiggestHotelPointsRedeemed
     */
    hotelStay?: GondolaMappedHotelStay;
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedBiggestHotelPointsRedeemed
     */
    points?: number;
}

/**
 * Check if a given object implements the GondolaMappedBiggestHotelPointsRedeemed interface.
 */
export function instanceOfGondolaMappedBiggestHotelPointsRedeemed(value: object): value is GondolaMappedBiggestHotelPointsRedeemed {
    return true;
}

export function GondolaMappedBiggestHotelPointsRedeemedFromJSON(json: any): GondolaMappedBiggestHotelPointsRedeemed {
    return GondolaMappedBiggestHotelPointsRedeemedFromJSONTyped(json, false);
}

export function GondolaMappedBiggestHotelPointsRedeemedFromJSONTyped(json: any, ignoreDiscriminator: boolean): GondolaMappedBiggestHotelPointsRedeemed {
    if (json == null) {
        return json;
    }
    return {
        
        'hotelStay': json['hotel_stay'] == null ? undefined : GondolaMappedHotelStayFromJSON(json['hotel_stay']),
        'points': json['points'] == null ? undefined : json['points'],
    };
}

export function GondolaMappedBiggestHotelPointsRedeemedToJSON(value?: GondolaMappedBiggestHotelPointsRedeemed | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'hotel_stay': GondolaMappedHotelStayToJSON(value['hotelStay']),
        'points': value['points'],
    };
}

