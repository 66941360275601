/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TravelerType = {
    NatureNomad: 'Nature Nomad',
    UrbanExplorer: 'Urban Explorer',
    MemoryMaker: 'Memory Maker',
    CultureSeeker: 'Culture Seeker',
    CoastalWanderer: 'Coastal Wanderer'
} as const;
export type TravelerType = typeof TravelerType[keyof typeof TravelerType];


export function instanceOfTravelerType(value: any): boolean {
    for (const key in TravelerType) {
        if (Object.prototype.hasOwnProperty.call(TravelerType, key)) {
            if ((TravelerType as Record<string, TravelerType>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TravelerTypeFromJSON(json: any): TravelerType {
    return TravelerTypeFromJSONTyped(json, false);
}

export function TravelerTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TravelerType {
    return json as TravelerType;
}

export function TravelerTypeToJSON(value?: TravelerType | null): any {
    return value as any;
}
