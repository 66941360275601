/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The duration of a transfer
 * @export
 */
export const TransferDuration = {
    Instant: 'instant',
    OneDay: 'one_day',
    TwoDays: 'two_days',
    ThreeDays: 'three_days'
} as const;
export type TransferDuration = typeof TransferDuration[keyof typeof TransferDuration];


export function instanceOfTransferDuration(value: any): boolean {
    for (const key in TransferDuration) {
        if (Object.prototype.hasOwnProperty.call(TransferDuration, key)) {
            if ((TransferDuration as Record<string, TransferDuration>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TransferDurationFromJSON(json: any): TransferDuration {
    return TransferDurationFromJSONTyped(json, false);
}

export function TransferDurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransferDuration {
    return json as TransferDuration;
}

export function TransferDurationToJSON(value?: TransferDuration | null): any {
    return value as any;
}
