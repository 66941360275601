/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GondolaMappedCity } from './GondolaMappedCity';
import {
    GondolaMappedCityFromJSON,
    GondolaMappedCityFromJSONTyped,
    GondolaMappedCityToJSON,
} from './GondolaMappedCity';

/**
 * 
 * @export
 * @interface GondolaMappedTotalCitiesVisited
 */
export interface GondolaMappedTotalCitiesVisited {
    /**
     * 
     * @type {Array<GondolaMappedCity>}
     * @memberof GondolaMappedTotalCitiesVisited
     */
    items: Array<GondolaMappedCity>;
}

/**
 * Check if a given object implements the GondolaMappedTotalCitiesVisited interface.
 */
export function instanceOfGondolaMappedTotalCitiesVisited(value: object): value is GondolaMappedTotalCitiesVisited {
    if (!('items' in value) || value['items'] === undefined) return false;
    return true;
}

export function GondolaMappedTotalCitiesVisitedFromJSON(json: any): GondolaMappedTotalCitiesVisited {
    return GondolaMappedTotalCitiesVisitedFromJSONTyped(json, false);
}

export function GondolaMappedTotalCitiesVisitedFromJSONTyped(json: any, ignoreDiscriminator: boolean): GondolaMappedTotalCitiesVisited {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(GondolaMappedCityFromJSON)),
    };
}

export function GondolaMappedTotalCitiesVisitedToJSON(value?: GondolaMappedTotalCitiesVisited | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(GondolaMappedCityToJSON)),
    };
}

