/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GondolaMappedTotalCountriesVisited } from './GondolaMappedTotalCountriesVisited';
import {
    GondolaMappedTotalCountriesVisitedFromJSON,
    GondolaMappedTotalCountriesVisitedFromJSONTyped,
    GondolaMappedTotalCountriesVisitedToJSON,
} from './GondolaMappedTotalCountriesVisited';
import type { GondolaMappedHotelStays } from './GondolaMappedHotelStays';
import {
    GondolaMappedHotelStaysFromJSON,
    GondolaMappedHotelStaysFromJSONTyped,
    GondolaMappedHotelStaysToJSON,
} from './GondolaMappedHotelStays';
import type { GondolaMappedTotalCitiesVisited } from './GondolaMappedTotalCitiesVisited';
import {
    GondolaMappedTotalCitiesVisitedFromJSON,
    GondolaMappedTotalCitiesVisitedFromJSONTyped,
    GondolaMappedTotalCitiesVisitedToJSON,
} from './GondolaMappedTotalCitiesVisited';
import type { GondolaMappedPointsRedeemedCurrentYear } from './GondolaMappedPointsRedeemedCurrentYear';
import {
    GondolaMappedPointsRedeemedCurrentYearFromJSON,
    GondolaMappedPointsRedeemedCurrentYearFromJSONTyped,
    GondolaMappedPointsRedeemedCurrentYearToJSON,
} from './GondolaMappedPointsRedeemedCurrentYear';
import type { GondolaMappedCurrentYearEndPoints } from './GondolaMappedCurrentYearEndPoints';
import {
    GondolaMappedCurrentYearEndPointsFromJSON,
    GondolaMappedCurrentYearEndPointsFromJSONTyped,
    GondolaMappedCurrentYearEndPointsToJSON,
} from './GondolaMappedCurrentYearEndPoints';
import type { GondolaMappedTotalFlightsTaken } from './GondolaMappedTotalFlightsTaken';
import {
    GondolaMappedTotalFlightsTakenFromJSON,
    GondolaMappedTotalFlightsTakenFromJSONTyped,
    GondolaMappedTotalFlightsTakenToJSON,
} from './GondolaMappedTotalFlightsTaken';
import type { GondolaMappedTravelerType } from './GondolaMappedTravelerType';
import {
    GondolaMappedTravelerTypeFromJSON,
    GondolaMappedTravelerTypeFromJSONTyped,
    GondolaMappedTravelerTypeToJSON,
} from './GondolaMappedTravelerType';

/**
 * 
 * @export
 * @interface GondolaMappedResponse
 */
export interface GondolaMappedResponse {
    /**
     * 
     * @type {GondolaMappedTotalCountriesVisited}
     * @memberof GondolaMappedResponse
     */
    totalCountriesVisited?: GondolaMappedTotalCountriesVisited;
    /**
     * 
     * @type {GondolaMappedTotalCitiesVisited}
     * @memberof GondolaMappedResponse
     */
    totalCitiesVisited?: GondolaMappedTotalCitiesVisited;
    /**
     * 
     * @type {GondolaMappedTotalFlightsTaken}
     * @memberof GondolaMappedResponse
     */
    totalFlightsTaken?: GondolaMappedTotalFlightsTaken;
    /**
     * 
     * @type {GondolaMappedHotelStays}
     * @memberof GondolaMappedResponse
     */
    hotelStays?: GondolaMappedHotelStays;
    /**
     * 
     * @type {GondolaMappedCurrentYearEndPoints}
     * @memberof GondolaMappedResponse
     */
    currentYearEndPoints?: GondolaMappedCurrentYearEndPoints;
    /**
     * 
     * @type {GondolaMappedPointsRedeemedCurrentYear}
     * @memberof GondolaMappedResponse
     */
    pointsRedeemedCurrentYear?: GondolaMappedPointsRedeemedCurrentYear;
    /**
     * 
     * @type {GondolaMappedTravelerType}
     * @memberof GondolaMappedResponse
     */
    travelerType?: GondolaMappedTravelerType;
}

/**
 * Check if a given object implements the GondolaMappedResponse interface.
 */
export function instanceOfGondolaMappedResponse(value: object): value is GondolaMappedResponse {
    return true;
}

export function GondolaMappedResponseFromJSON(json: any): GondolaMappedResponse {
    return GondolaMappedResponseFromJSONTyped(json, false);
}

export function GondolaMappedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GondolaMappedResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'totalCountriesVisited': json['total_countries_visited'] == null ? undefined : GondolaMappedTotalCountriesVisitedFromJSON(json['total_countries_visited']),
        'totalCitiesVisited': json['total_cities_visited'] == null ? undefined : GondolaMappedTotalCitiesVisitedFromJSON(json['total_cities_visited']),
        'totalFlightsTaken': json['total_flights_taken'] == null ? undefined : GondolaMappedTotalFlightsTakenFromJSON(json['total_flights_taken']),
        'hotelStays': json['hotel_stays'] == null ? undefined : GondolaMappedHotelStaysFromJSON(json['hotel_stays']),
        'currentYearEndPoints': json['current_year_end_points'] == null ? undefined : GondolaMappedCurrentYearEndPointsFromJSON(json['current_year_end_points']),
        'pointsRedeemedCurrentYear': json['points_redeemed_current_year'] == null ? undefined : GondolaMappedPointsRedeemedCurrentYearFromJSON(json['points_redeemed_current_year']),
        'travelerType': json['traveler_type'] == null ? undefined : GondolaMappedTravelerTypeFromJSON(json['traveler_type']),
    };
}

export function GondolaMappedResponseToJSON(value?: GondolaMappedResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'total_countries_visited': GondolaMappedTotalCountriesVisitedToJSON(value['totalCountriesVisited']),
        'total_cities_visited': GondolaMappedTotalCitiesVisitedToJSON(value['totalCitiesVisited']),
        'total_flights_taken': GondolaMappedTotalFlightsTakenToJSON(value['totalFlightsTaken']),
        'hotel_stays': GondolaMappedHotelStaysToJSON(value['hotelStays']),
        'current_year_end_points': GondolaMappedCurrentYearEndPointsToJSON(value['currentYearEndPoints']),
        'points_redeemed_current_year': GondolaMappedPointsRedeemedCurrentYearToJSON(value['pointsRedeemedCurrentYear']),
        'traveler_type': GondolaMappedTravelerTypeToJSON(value['travelerType']),
    };
}

