/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CurrentRateMatch = {
    Exact: 'EXACT',
    Lowest: 'LOWEST',
    Closest: 'CLOSEST',
    Unavailable: 'UNAVAILABLE'
} as const;
export type CurrentRateMatch = typeof CurrentRateMatch[keyof typeof CurrentRateMatch];


export function instanceOfCurrentRateMatch(value: any): boolean {
    for (const key in CurrentRateMatch) {
        if (Object.prototype.hasOwnProperty.call(CurrentRateMatch, key)) {
            if ((CurrentRateMatch as Record<string, CurrentRateMatch>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function CurrentRateMatchFromJSON(json: any): CurrentRateMatch {
    return CurrentRateMatchFromJSONTyped(json, false);
}

export function CurrentRateMatchFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrentRateMatch {
    return json as CurrentRateMatch;
}

export function CurrentRateMatchToJSON(value?: CurrentRateMatch | null): any {
    return value as any;
}
