/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Coordinates } from './Coordinates';
import {
    CoordinatesFromJSON,
    CoordinatesFromJSONTyped,
    CoordinatesToJSON,
} from './Coordinates';

/**
 * 
 * @export
 * @interface GondolaMappedCity
 */
export interface GondolaMappedCity {
    /**
     * 
     * @type {string}
     * @memberof GondolaMappedCity
     */
    cityName: string;
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedCity
     */
    year: number;
    /**
     * 
     * @type {Coordinates}
     * @memberof GondolaMappedCity
     */
    coordinates: Coordinates;
}

/**
 * Check if a given object implements the GondolaMappedCity interface.
 */
export function instanceOfGondolaMappedCity(value: object): value is GondolaMappedCity {
    if (!('cityName' in value) || value['cityName'] === undefined) return false;
    if (!('year' in value) || value['year'] === undefined) return false;
    if (!('coordinates' in value) || value['coordinates'] === undefined) return false;
    return true;
}

export function GondolaMappedCityFromJSON(json: any): GondolaMappedCity {
    return GondolaMappedCityFromJSONTyped(json, false);
}

export function GondolaMappedCityFromJSONTyped(json: any, ignoreDiscriminator: boolean): GondolaMappedCity {
    if (json == null) {
        return json;
    }
    return {
        
        'cityName': json['city_name'],
        'year': json['year'],
        'coordinates': CoordinatesFromJSON(json['coordinates']),
    };
}

export function GondolaMappedCityToJSON(value?: GondolaMappedCity | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'city_name': value['cityName'],
        'year': value['year'],
        'coordinates': CoordinatesToJSON(value['coordinates']),
    };
}

