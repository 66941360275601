/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CurrentRateMatch } from './CurrentRateMatch';
import {
    CurrentRateMatchFromJSON,
    CurrentRateMatchFromJSONTyped,
    CurrentRateMatchToJSON,
} from './CurrentRateMatch';
import type { BookingDetails } from './BookingDetails';
import {
    BookingDetailsFromJSON,
    BookingDetailsFromJSONTyped,
    BookingDetailsToJSON,
} from './BookingDetails';
import type { CurrencyAmount } from './CurrencyAmount';
import {
    CurrencyAmountFromJSON,
    CurrencyAmountFromJSONTyped,
    CurrencyAmountToJSON,
} from './CurrencyAmount';

/**
 * 
 * @export
 * @interface Hotel
 */
export interface Hotel {
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    emailId?: string;
    /**
     * 
     * @type {number}
     * @memberof Hotel
     */
    vervotechPropertyId?: number;
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof Hotel
     */
    checkInDatetime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Hotel
     */
    checkOutDatetime?: Date;
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    confirmationNumber?: string;
    /**
     * Gondola-internal booking ID. For user-facing Gondola confirmation number, use gondola_booking_id instead.
     * @type {number}
     * @memberof Hotel
     */
    bookingId?: number;
    /**
     * User-facing Gondola booking confirmation number.
     * @type {string}
     * @memberof Hotel
     */
    gondolaBookingId?: string;
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    roomType?: string;
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    guests?: string;
    /**
     * 
     * @type {number}
     * @memberof Hotel
     */
    totalCost?: number;
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    currency?: string;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof Hotel
     */
    currentPrice?: CurrencyAmount;
    /**
     * 
     * @type {Date}
     * @memberof Hotel
     */
    currentPriceUpdatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Hotel
     */
    cancellableUntil?: Date;
    /**
     * 
     * @type {CurrentRateMatch}
     * @memberof Hotel
     */
    currentRateMatch?: CurrentRateMatch;
    /**
     * 
     * @type {number}
     * @memberof Hotel
     */
    totalPointsRedeemed?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Hotel
     */
    cancelled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Hotel
     */
    refundable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    hotelSupplier?: string;
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    bookingAgent?: string;
    /**
     * 
     * @type {Date}
     * @memberof Hotel
     */
    bookingDate?: Date;
    /**
     * 
     * @type {BookingDetails}
     * @memberof Hotel
     */
    bookingDetails?: BookingDetails;
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    tripId?: string;
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    scheduledEventCode?: string;
}

/**
 * Check if a given object implements the Hotel interface.
 */
export function instanceOfHotel(value: object): value is Hotel {
    return true;
}

export function HotelFromJSON(json: any): Hotel {
    return HotelFromJSONTyped(json, false);
}

export function HotelFromJSONTyped(json: any, ignoreDiscriminator: boolean): Hotel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'emailId': json['email_id'] == null ? undefined : json['email_id'],
        'vervotechPropertyId': json['vervotech_property_id'] == null ? undefined : json['vervotech_property_id'],
        'name': json['name'] == null ? undefined : json['name'],
        'checkInDatetime': json['check_in_datetime'] == null ? undefined : (new Date(json['check_in_datetime'])),
        'checkOutDatetime': json['check_out_datetime'] == null ? undefined : (new Date(json['check_out_datetime'])),
        'confirmationNumber': json['confirmation_number'] == null ? undefined : json['confirmation_number'],
        'bookingId': json['booking_id'] == null ? undefined : json['booking_id'],
        'gondolaBookingId': json['gondola_booking_id'] == null ? undefined : json['gondola_booking_id'],
        'roomType': json['room_type'] == null ? undefined : json['room_type'],
        'guests': json['guests'] == null ? undefined : json['guests'],
        'totalCost': json['total_cost'] == null ? undefined : json['total_cost'],
        'currency': json['currency'] == null ? undefined : json['currency'],
        'currentPrice': json['current_price'] == null ? undefined : CurrencyAmountFromJSON(json['current_price']),
        'currentPriceUpdatedAt': json['current_price_updated_at'] == null ? undefined : (new Date(json['current_price_updated_at'])),
        'cancellableUntil': json['cancellable_until'] == null ? undefined : (new Date(json['cancellable_until'])),
        'currentRateMatch': json['current_rate_match'] == null ? undefined : CurrentRateMatchFromJSON(json['current_rate_match']),
        'totalPointsRedeemed': json['total_points_redeemed'] == null ? undefined : json['total_points_redeemed'],
        'cancelled': json['cancelled'] == null ? undefined : json['cancelled'],
        'refundable': json['refundable'] == null ? undefined : json['refundable'],
        'hotelSupplier': json['hotel_supplier'] == null ? undefined : json['hotel_supplier'],
        'bookingAgent': json['booking_agent'] == null ? undefined : json['booking_agent'],
        'bookingDate': json['booking_date'] == null ? undefined : (new Date(json['booking_date'])),
        'bookingDetails': json['booking_details'] == null ? undefined : BookingDetailsFromJSON(json['booking_details']),
        'tripId': json['trip_id'] == null ? undefined : json['trip_id'],
        'scheduledEventCode': json['scheduled_event_code'] == null ? undefined : json['scheduled_event_code'],
    };
}

export function HotelToJSON(value?: Hotel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'email_id': value['emailId'],
        'vervotech_property_id': value['vervotechPropertyId'],
        'name': value['name'],
        'check_in_datetime': value['checkInDatetime'] == null ? undefined : ((value['checkInDatetime']).toISOString()),
        'check_out_datetime': value['checkOutDatetime'] == null ? undefined : ((value['checkOutDatetime']).toISOString()),
        'confirmation_number': value['confirmationNumber'],
        'booking_id': value['bookingId'],
        'gondola_booking_id': value['gondolaBookingId'],
        'room_type': value['roomType'],
        'guests': value['guests'],
        'total_cost': value['totalCost'],
        'currency': value['currency'],
        'current_price': CurrencyAmountToJSON(value['currentPrice']),
        'current_price_updated_at': value['currentPriceUpdatedAt'] == null ? undefined : ((value['currentPriceUpdatedAt']).toISOString()),
        'cancellable_until': value['cancellableUntil'] == null ? undefined : ((value['cancellableUntil']).toISOString()),
        'current_rate_match': CurrentRateMatchToJSON(value['currentRateMatch']),
        'total_points_redeemed': value['totalPointsRedeemed'],
        'cancelled': value['cancelled'],
        'refundable': value['refundable'],
        'hotel_supplier': value['hotelSupplier'],
        'booking_agent': value['bookingAgent'],
        'booking_date': value['bookingDate'] == null ? undefined : ((value['bookingDate']).toISOString()),
        'booking_details': BookingDetailsToJSON(value['bookingDetails']),
        'trip_id': value['tripId'],
        'scheduled_event_code': value['scheduledEventCode'],
    };
}

