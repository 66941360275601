/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TravelerType } from './TravelerType';
import {
    TravelerTypeFromJSON,
    TravelerTypeFromJSONTyped,
    TravelerTypeToJSON,
} from './TravelerType';
import type { GondolaMappedHotelStay } from './GondolaMappedHotelStay';
import {
    GondolaMappedHotelStayFromJSON,
    GondolaMappedHotelStayFromJSONTyped,
    GondolaMappedHotelStayToJSON,
} from './GondolaMappedHotelStay';

/**
 * 
 * @export
 * @interface GondolaMappedTravelerType
 */
export interface GondolaMappedTravelerType {
    /**
     * 
     * @type {TravelerType}
     * @memberof GondolaMappedTravelerType
     */
    travelerType?: TravelerType;
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedTravelerType
     */
    percentageOfUserBaseHavingTheSameTravelerType?: number;
    /**
     * 
     * @type {GondolaMappedHotelStay}
     * @memberof GondolaMappedTravelerType
     */
    recommendedHotel?: GondolaMappedHotelStay;
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedTravelerType
     */
    flightsPercentile: number;
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedTravelerType
     */
    hotelStaysPercentile: number;
}

/**
 * Check if a given object implements the GondolaMappedTravelerType interface.
 */
export function instanceOfGondolaMappedTravelerType(value: object): value is GondolaMappedTravelerType {
    if (!('flightsPercentile' in value) || value['flightsPercentile'] === undefined) return false;
    if (!('hotelStaysPercentile' in value) || value['hotelStaysPercentile'] === undefined) return false;
    return true;
}

export function GondolaMappedTravelerTypeFromJSON(json: any): GondolaMappedTravelerType {
    return GondolaMappedTravelerTypeFromJSONTyped(json, false);
}

export function GondolaMappedTravelerTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): GondolaMappedTravelerType {
    if (json == null) {
        return json;
    }
    return {
        
        'travelerType': json['traveler_type'] == null ? undefined : TravelerTypeFromJSON(json['traveler_type']),
        'percentageOfUserBaseHavingTheSameTravelerType': json['percentage_of_user_base_having_the_same_traveler_type'] == null ? undefined : json['percentage_of_user_base_having_the_same_traveler_type'],
        'recommendedHotel': json['recommended_hotel'] == null ? undefined : GondolaMappedHotelStayFromJSON(json['recommended_hotel']),
        'flightsPercentile': json['flights_percentile'],
        'hotelStaysPercentile': json['hotel_stays_percentile'],
    };
}

export function GondolaMappedTravelerTypeToJSON(value?: GondolaMappedTravelerType | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'traveler_type': TravelerTypeToJSON(value['travelerType']),
        'percentage_of_user_base_having_the_same_traveler_type': value['percentageOfUserBaseHavingTheSameTravelerType'],
        'recommended_hotel': GondolaMappedHotelStayToJSON(value['recommendedHotel']),
        'flights_percentile': value['flightsPercentile'],
        'hotel_stays_percentile': value['hotelStaysPercentile'],
    };
}

