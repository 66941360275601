/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GondolaMappedBiggestHotelPointsEarned
 */
export interface GondolaMappedBiggestHotelPointsEarned {
    /**
     * 
     * @type {string}
     * @memberof GondolaMappedBiggestHotelPointsEarned
     */
    hotelName?: string;
    /**
     * 
     * @type {string}
     * @memberof GondolaMappedBiggestHotelPointsEarned
     */
    hotelCity?: string;
    /**
     * 
     * @type {string}
     * @memberof GondolaMappedBiggestHotelPointsEarned
     */
    hotelCountry?: string;
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedBiggestHotelPointsEarned
     */
    points?: number;
}

/**
 * Check if a given object implements the GondolaMappedBiggestHotelPointsEarned interface.
 */
export function instanceOfGondolaMappedBiggestHotelPointsEarned(value: object): value is GondolaMappedBiggestHotelPointsEarned {
    return true;
}

export function GondolaMappedBiggestHotelPointsEarnedFromJSON(json: any): GondolaMappedBiggestHotelPointsEarned {
    return GondolaMappedBiggestHotelPointsEarnedFromJSONTyped(json, false);
}

export function GondolaMappedBiggestHotelPointsEarnedFromJSONTyped(json: any, ignoreDiscriminator: boolean): GondolaMappedBiggestHotelPointsEarned {
    if (json == null) {
        return json;
    }
    return {
        
        'hotelName': json['hotel_name'] == null ? undefined : json['hotel_name'],
        'hotelCity': json['hotel_city'] == null ? undefined : json['hotel_city'],
        'hotelCountry': json['hotel_country'] == null ? undefined : json['hotel_country'],
        'points': json['points'] == null ? undefined : json['points'],
    };
}

export function GondolaMappedBiggestHotelPointsEarnedToJSON(value?: GondolaMappedBiggestHotelPointsEarned | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'hotel_name': value['hotelName'],
        'hotel_city': value['hotelCity'],
        'hotel_country': value['hotelCountry'],
        'points': value['points'],
    };
}

