/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GondolaMappedBiggestHotelPointsEarned } from './GondolaMappedBiggestHotelPointsEarned';
import {
    GondolaMappedBiggestHotelPointsEarnedFromJSON,
    GondolaMappedBiggestHotelPointsEarnedFromJSONTyped,
    GondolaMappedBiggestHotelPointsEarnedToJSON,
} from './GondolaMappedBiggestHotelPointsEarned';

/**
 * 
 * @export
 * @interface GondolaMappedCurrentYearEndPoints
 */
export interface GondolaMappedCurrentYearEndPoints {
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedCurrentYearEndPoints
     */
    hotelPoints?: number;
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedCurrentYearEndPoints
     */
    creditCardPoints?: number;
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedCurrentYearEndPoints
     */
    flightPoints?: number;
    /**
     * 
     * @type {GondolaMappedBiggestHotelPointsEarned}
     * @memberof GondolaMappedCurrentYearEndPoints
     */
    biggestHotelPointsEarned?: GondolaMappedBiggestHotelPointsEarned;
}

/**
 * Check if a given object implements the GondolaMappedCurrentYearEndPoints interface.
 */
export function instanceOfGondolaMappedCurrentYearEndPoints(value: object): value is GondolaMappedCurrentYearEndPoints {
    return true;
}

export function GondolaMappedCurrentYearEndPointsFromJSON(json: any): GondolaMappedCurrentYearEndPoints {
    return GondolaMappedCurrentYearEndPointsFromJSONTyped(json, false);
}

export function GondolaMappedCurrentYearEndPointsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GondolaMappedCurrentYearEndPoints {
    if (json == null) {
        return json;
    }
    return {
        
        'hotelPoints': json['hotel_points'] == null ? undefined : json['hotel_points'],
        'creditCardPoints': json['credit_card_points'] == null ? undefined : json['credit_card_points'],
        'flightPoints': json['flight_points'] == null ? undefined : json['flight_points'],
        'biggestHotelPointsEarned': json['biggest_hotel_points_earned'] == null ? undefined : GondolaMappedBiggestHotelPointsEarnedFromJSON(json['biggest_hotel_points_earned']),
    };
}

export function GondolaMappedCurrentYearEndPointsToJSON(value?: GondolaMappedCurrentYearEndPoints | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'hotel_points': value['hotelPoints'],
        'credit_card_points': value['creditCardPoints'],
        'flight_points': value['flightPoints'],
        'biggest_hotel_points_earned': GondolaMappedBiggestHotelPointsEarnedToJSON(value['biggestHotelPointsEarned']),
    };
}

