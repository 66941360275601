/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Coordinates } from './Coordinates';
import {
    CoordinatesFromJSON,
    CoordinatesFromJSONTyped,
    CoordinatesToJSON,
} from './Coordinates';

/**
 * 
 * @export
 * @interface GondolaMappedHotelStay
 */
export interface GondolaMappedHotelStay {
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedHotelStay
     */
    vervotechPropertyId?: number;
    /**
     * 
     * @type {string}
     * @memberof GondolaMappedHotelStay
     */
    heroImage?: string;
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedHotelStay
     */
    bookingId?: number;
    /**
     * 
     * @type {string}
     * @memberof GondolaMappedHotelStay
     */
    propertyName?: string;
    /**
     * 
     * @type {string}
     * @memberof GondolaMappedHotelStay
     */
    cityName?: string;
    /**
     * 
     * @type {string}
     * @memberof GondolaMappedHotelStay
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof GondolaMappedHotelStay
     */
    chainName?: string;
    /**
     * 
     * @type {string}
     * @memberof GondolaMappedHotelStay
     */
    brandName?: string;
    /**
     * 
     * @type {Date}
     * @memberof GondolaMappedHotelStay
     */
    checkInDatetime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GondolaMappedHotelStay
     */
    checkOutDatetime?: Date;
    /**
     * 
     * @type {Coordinates}
     * @memberof GondolaMappedHotelStay
     */
    coordinates?: Coordinates;
}

/**
 * Check if a given object implements the GondolaMappedHotelStay interface.
 */
export function instanceOfGondolaMappedHotelStay(value: object): value is GondolaMappedHotelStay {
    return true;
}

export function GondolaMappedHotelStayFromJSON(json: any): GondolaMappedHotelStay {
    return GondolaMappedHotelStayFromJSONTyped(json, false);
}

export function GondolaMappedHotelStayFromJSONTyped(json: any, ignoreDiscriminator: boolean): GondolaMappedHotelStay {
    if (json == null) {
        return json;
    }
    return {
        
        'vervotechPropertyId': json['vervotech_property_id'] == null ? undefined : json['vervotech_property_id'],
        'heroImage': json['hero_image'] == null ? undefined : json['hero_image'],
        'bookingId': json['booking_id'] == null ? undefined : json['booking_id'],
        'propertyName': json['property_name'] == null ? undefined : json['property_name'],
        'cityName': json['city_name'] == null ? undefined : json['city_name'],
        'countryCode': json['country_code'] == null ? undefined : json['country_code'],
        'chainName': json['chain_name'] == null ? undefined : json['chain_name'],
        'brandName': json['brand_name'] == null ? undefined : json['brand_name'],
        'checkInDatetime': json['check_in_datetime'] == null ? undefined : (new Date(json['check_in_datetime'])),
        'checkOutDatetime': json['check_out_datetime'] == null ? undefined : (new Date(json['check_out_datetime'])),
        'coordinates': json['coordinates'] == null ? undefined : CoordinatesFromJSON(json['coordinates']),
    };
}

export function GondolaMappedHotelStayToJSON(value?: GondolaMappedHotelStay | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'vervotech_property_id': value['vervotechPropertyId'],
        'hero_image': value['heroImage'],
        'booking_id': value['bookingId'],
        'property_name': value['propertyName'],
        'city_name': value['cityName'],
        'country_code': value['countryCode'],
        'chain_name': value['chainName'],
        'brand_name': value['brandName'],
        'check_in_datetime': value['checkInDatetime'] == null ? undefined : ((value['checkInDatetime']).toISOString()),
        'check_out_datetime': value['checkOutDatetime'] == null ? undefined : ((value['checkOutDatetime']).toISOString()),
        'coordinates': CoordinatesToJSON(value['coordinates']),
    };
}

