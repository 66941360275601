/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GondolaMappedHotelStay } from './GondolaMappedHotelStay';
import {
    GondolaMappedHotelStayFromJSON,
    GondolaMappedHotelStayFromJSONTyped,
    GondolaMappedHotelStayToJSON,
} from './GondolaMappedHotelStay';

/**
 * 
 * @export
 * @interface GondolaMappedHotelStays
 */
export interface GondolaMappedHotelStays {
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedHotelStays
     */
    currentYearNumberOfNightsStayedInHotels: number;
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedHotelStays
     */
    currentYearTotalNumberOfHotels?: number;
    /**
     * 
     * @type {GondolaMappedHotelStay}
     * @memberof GondolaMappedHotelStays
     */
    longestHotelStay?: GondolaMappedHotelStay;
    /**
     * 
     * @type {Array<GondolaMappedHotelStay>}
     * @memberof GondolaMappedHotelStays
     */
    topFourHotelStays: Array<GondolaMappedHotelStay>;
}

/**
 * Check if a given object implements the GondolaMappedHotelStays interface.
 */
export function instanceOfGondolaMappedHotelStays(value: object): value is GondolaMappedHotelStays {
    if (!('currentYearNumberOfNightsStayedInHotels' in value) || value['currentYearNumberOfNightsStayedInHotels'] === undefined) return false;
    if (!('topFourHotelStays' in value) || value['topFourHotelStays'] === undefined) return false;
    return true;
}

export function GondolaMappedHotelStaysFromJSON(json: any): GondolaMappedHotelStays {
    return GondolaMappedHotelStaysFromJSONTyped(json, false);
}

export function GondolaMappedHotelStaysFromJSONTyped(json: any, ignoreDiscriminator: boolean): GondolaMappedHotelStays {
    if (json == null) {
        return json;
    }
    return {
        
        'currentYearNumberOfNightsStayedInHotels': json['current_year_number_of_nights_stayed_in_hotels'],
        'currentYearTotalNumberOfHotels': json['current_year_total_number_of_hotels'] == null ? undefined : json['current_year_total_number_of_hotels'],
        'longestHotelStay': json['longest_hotel_stay'] == null ? undefined : GondolaMappedHotelStayFromJSON(json['longest_hotel_stay']),
        'topFourHotelStays': ((json['top_four_hotel_stays'] as Array<any>).map(GondolaMappedHotelStayFromJSON)),
    };
}

export function GondolaMappedHotelStaysToJSON(value?: GondolaMappedHotelStays | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'current_year_number_of_nights_stayed_in_hotels': value['currentYearNumberOfNightsStayedInHotels'],
        'current_year_total_number_of_hotels': value['currentYearTotalNumberOfHotels'],
        'longest_hotel_stay': GondolaMappedHotelStayToJSON(value['longestHotelStay']),
        'top_four_hotel_stays': ((value['topFourHotelStays'] as Array<any>).map(GondolaMappedHotelStayToJSON)),
    };
}

