/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GondolaMappedFlight } from './GondolaMappedFlight';
import {
    GondolaMappedFlightFromJSON,
    GondolaMappedFlightFromJSONTyped,
    GondolaMappedFlightToJSON,
} from './GondolaMappedFlight';
import type { Coordinates } from './Coordinates';
import {
    CoordinatesFromJSON,
    CoordinatesFromJSONTyped,
    CoordinatesToJSON,
} from './Coordinates';

/**
 * 
 * @export
 * @interface GondolaMappedTotalFlightsTaken
 */
export interface GondolaMappedTotalFlightsTaken {
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedTotalFlightsTaken
     */
    lifetimeTotalNumberOfFlights?: number;
    /**
     * 
     * @type {string}
     * @memberof GondolaMappedTotalFlightsTaken
     */
    currentYearTopAirline?: string;
    /**
     * 
     * @type {string}
     * @memberof GondolaMappedTotalFlightsTaken
     */
    longestFlightDepartureIata?: string;
    /**
     * 
     * @type {string}
     * @memberof GondolaMappedTotalFlightsTaken
     */
    longestFlightArrivalIata?: string;
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedTotalFlightsTaken
     */
    longestFlightDurationInSeconds?: number;
    /**
     * 
     * @type {Coordinates}
     * @memberof GondolaMappedTotalFlightsTaken
     */
    longestFlightDepartureCoordinates?: Coordinates;
    /**
     * 
     * @type {Coordinates}
     * @memberof GondolaMappedTotalFlightsTaken
     */
    longestFlightArrivalCoordinates?: Coordinates;
    /**
     * 
     * @type {Array<GondolaMappedFlight>}
     * @memberof GondolaMappedTotalFlightsTaken
     */
    currentYearFlights?: Array<GondolaMappedFlight>;
}

/**
 * Check if a given object implements the GondolaMappedTotalFlightsTaken interface.
 */
export function instanceOfGondolaMappedTotalFlightsTaken(value: object): value is GondolaMappedTotalFlightsTaken {
    return true;
}

export function GondolaMappedTotalFlightsTakenFromJSON(json: any): GondolaMappedTotalFlightsTaken {
    return GondolaMappedTotalFlightsTakenFromJSONTyped(json, false);
}

export function GondolaMappedTotalFlightsTakenFromJSONTyped(json: any, ignoreDiscriminator: boolean): GondolaMappedTotalFlightsTaken {
    if (json == null) {
        return json;
    }
    return {
        
        'lifetimeTotalNumberOfFlights': json['lifetime_total_number_of_flights'] == null ? undefined : json['lifetime_total_number_of_flights'],
        'currentYearTopAirline': json['current_year_top_airline'] == null ? undefined : json['current_year_top_airline'],
        'longestFlightDepartureIata': json['longest_flight_departure_iata'] == null ? undefined : json['longest_flight_departure_iata'],
        'longestFlightArrivalIata': json['longest_flight_arrival_iata'] == null ? undefined : json['longest_flight_arrival_iata'],
        'longestFlightDurationInSeconds': json['longest_flight_duration_in_seconds'] == null ? undefined : json['longest_flight_duration_in_seconds'],
        'longestFlightDepartureCoordinates': json['longest_flight_departure_coordinates'] == null ? undefined : CoordinatesFromJSON(json['longest_flight_departure_coordinates']),
        'longestFlightArrivalCoordinates': json['longest_flight_arrival_coordinates'] == null ? undefined : CoordinatesFromJSON(json['longest_flight_arrival_coordinates']),
        'currentYearFlights': json['current_year_flights'] == null ? undefined : ((json['current_year_flights'] as Array<any>).map(GondolaMappedFlightFromJSON)),
    };
}

export function GondolaMappedTotalFlightsTakenToJSON(value?: GondolaMappedTotalFlightsTaken | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'lifetime_total_number_of_flights': value['lifetimeTotalNumberOfFlights'],
        'current_year_top_airline': value['currentYearTopAirline'],
        'longest_flight_departure_iata': value['longestFlightDepartureIata'],
        'longest_flight_arrival_iata': value['longestFlightArrivalIata'],
        'longest_flight_duration_in_seconds': value['longestFlightDurationInSeconds'],
        'longest_flight_departure_coordinates': CoordinatesToJSON(value['longestFlightDepartureCoordinates']),
        'longest_flight_arrival_coordinates': CoordinatesToJSON(value['longestFlightArrivalCoordinates']),
        'current_year_flights': value['currentYearFlights'] == null ? undefined : ((value['currentYearFlights'] as Array<any>).map(GondolaMappedFlightToJSON)),
    };
}

