/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GondolaMappedBiggestHotelPointsRedeemed } from './GondolaMappedBiggestHotelPointsRedeemed';
import {
    GondolaMappedBiggestHotelPointsRedeemedFromJSON,
    GondolaMappedBiggestHotelPointsRedeemedFromJSONTyped,
    GondolaMappedBiggestHotelPointsRedeemedToJSON,
} from './GondolaMappedBiggestHotelPointsRedeemed';
import type { GondolaMappedBiggestFlightPointsRedeemed } from './GondolaMappedBiggestFlightPointsRedeemed';
import {
    GondolaMappedBiggestFlightPointsRedeemedFromJSON,
    GondolaMappedBiggestFlightPointsRedeemedFromJSONTyped,
    GondolaMappedBiggestFlightPointsRedeemedToJSON,
} from './GondolaMappedBiggestFlightPointsRedeemed';

/**
 * 
 * @export
 * @interface GondolaMappedPointsRedeemedCurrentYear
 */
export interface GondolaMappedPointsRedeemedCurrentYear {
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedPointsRedeemedCurrentYear
     */
    hotelPoints?: number;
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedPointsRedeemedCurrentYear
     */
    creditCardPoints?: number;
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedPointsRedeemedCurrentYear
     */
    flightPoints?: number;
    /**
     * 
     * @type {GondolaMappedBiggestFlightPointsRedeemed}
     * @memberof GondolaMappedPointsRedeemedCurrentYear
     */
    biggestFlightPointsRedeemed?: GondolaMappedBiggestFlightPointsRedeemed;
    /**
     * 
     * @type {GondolaMappedBiggestHotelPointsRedeemed}
     * @memberof GondolaMappedPointsRedeemedCurrentYear
     */
    biggestHotelPointsRedeemed?: GondolaMappedBiggestHotelPointsRedeemed;
}

/**
 * Check if a given object implements the GondolaMappedPointsRedeemedCurrentYear interface.
 */
export function instanceOfGondolaMappedPointsRedeemedCurrentYear(value: object): value is GondolaMappedPointsRedeemedCurrentYear {
    return true;
}

export function GondolaMappedPointsRedeemedCurrentYearFromJSON(json: any): GondolaMappedPointsRedeemedCurrentYear {
    return GondolaMappedPointsRedeemedCurrentYearFromJSONTyped(json, false);
}

export function GondolaMappedPointsRedeemedCurrentYearFromJSONTyped(json: any, ignoreDiscriminator: boolean): GondolaMappedPointsRedeemedCurrentYear {
    if (json == null) {
        return json;
    }
    return {
        
        'hotelPoints': json['hotel_points'] == null ? undefined : json['hotel_points'],
        'creditCardPoints': json['credit_card_points'] == null ? undefined : json['credit_card_points'],
        'flightPoints': json['flight_points'] == null ? undefined : json['flight_points'],
        'biggestFlightPointsRedeemed': json['biggest_flight_points_redeemed'] == null ? undefined : GondolaMappedBiggestFlightPointsRedeemedFromJSON(json['biggest_flight_points_redeemed']),
        'biggestHotelPointsRedeemed': json['biggest_hotel_points_redeemed'] == null ? undefined : GondolaMappedBiggestHotelPointsRedeemedFromJSON(json['biggest_hotel_points_redeemed']),
    };
}

export function GondolaMappedPointsRedeemedCurrentYearToJSON(value?: GondolaMappedPointsRedeemedCurrentYear | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'hotel_points': value['hotelPoints'],
        'credit_card_points': value['creditCardPoints'],
        'flight_points': value['flightPoints'],
        'biggest_flight_points_redeemed': GondolaMappedBiggestFlightPointsRedeemedToJSON(value['biggestFlightPointsRedeemed']),
        'biggest_hotel_points_redeemed': GondolaMappedBiggestHotelPointsRedeemedToJSON(value['biggestHotelPointsRedeemed']),
    };
}

