/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Coordinates } from './Coordinates';
import {
    CoordinatesFromJSON,
    CoordinatesFromJSONTyped,
    CoordinatesToJSON,
} from './Coordinates';

/**
 * 
 * @export
 * @interface GondolaMappedFlight
 */
export interface GondolaMappedFlight {
    /**
     * 
     * @type {string}
     * @memberof GondolaMappedFlight
     */
    departureIata?: string;
    /**
     * 
     * @type {string}
     * @memberof GondolaMappedFlight
     */
    arrivalIata?: string;
    /**
     * 
     * @type {Date}
     * @memberof GondolaMappedFlight
     */
    departureDatetime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GondolaMappedFlight
     */
    arrivalDatetime?: Date;
    /**
     * 
     * @type {Coordinates}
     * @memberof GondolaMappedFlight
     */
    departureCoordinates?: Coordinates;
    /**
     * 
     * @type {Coordinates}
     * @memberof GondolaMappedFlight
     */
    arrivalCoordinates?: Coordinates;
}

/**
 * Check if a given object implements the GondolaMappedFlight interface.
 */
export function instanceOfGondolaMappedFlight(value: object): value is GondolaMappedFlight {
    return true;
}

export function GondolaMappedFlightFromJSON(json: any): GondolaMappedFlight {
    return GondolaMappedFlightFromJSONTyped(json, false);
}

export function GondolaMappedFlightFromJSONTyped(json: any, ignoreDiscriminator: boolean): GondolaMappedFlight {
    if (json == null) {
        return json;
    }
    return {
        
        'departureIata': json['departure_iata'] == null ? undefined : json['departure_iata'],
        'arrivalIata': json['arrival_iata'] == null ? undefined : json['arrival_iata'],
        'departureDatetime': json['departure_datetime'] == null ? undefined : (new Date(json['departure_datetime'])),
        'arrivalDatetime': json['arrival_datetime'] == null ? undefined : (new Date(json['arrival_datetime'])),
        'departureCoordinates': json['departure_coordinates'] == null ? undefined : CoordinatesFromJSON(json['departure_coordinates']),
        'arrivalCoordinates': json['arrival_coordinates'] == null ? undefined : CoordinatesFromJSON(json['arrival_coordinates']),
    };
}

export function GondolaMappedFlightToJSON(value?: GondolaMappedFlight | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'departure_iata': value['departureIata'],
        'arrival_iata': value['arrivalIata'],
        'departure_datetime': value['departureDatetime'] == null ? undefined : ((value['departureDatetime']).toISOString()),
        'arrival_datetime': value['arrivalDatetime'] == null ? undefined : ((value['arrivalDatetime']).toISOString()),
        'departure_coordinates': CoordinatesToJSON(value['departureCoordinates']),
        'arrival_coordinates': CoordinatesToJSON(value['arrivalCoordinates']),
    };
}

